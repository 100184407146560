import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'lil-gui'
import { FontLoader } from 'three/examples/jsm/loaders/FontLoader.js'
import { TextGeometry } from 'three/examples/jsm/geometries/TextGeometry.js'
import typefaceFont from 'three/examples/fonts/helvetiker_regular.typeface.json'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js'
import { OutlinePass } from 'three/examples/jsm/postprocessing/OutlinePass.js';
import { GammaCorrectionShader } from 'three/addons/shaders/GammaCorrectionShader.js';
import { FXAAShader } from 'three/addons/shaders/FXAAShader.js';
import { ShaderPass } from 'three/addons/postprocessing/ShaderPass.js';
import { gsap } from 'gsap';

/**
 * Loaders
 */
let sceneReady = false

const loadingBarElement = document.querySelector('.loading-bar')

const loadingManager= new THREE.LoadingManager(
    () =>
    {
       gsap.delayedCall(0.2,() =>{
            gsap.to(imageMaterial, { duration: 1, opacity: 1, delay: 1 });
            gsap.to(bakedMaterial, { duration: 1, opacity: 1, delay: 1 });
            gsap.to(screenMaterial, { duration: 1, opacity: 1, delay: 1 });
            gsap.to(materials, { duration: 1, opacity: 1, delay: 1.1 });


        
            loadingBarElement.classList.add('ended')
            loadingBarElement.style.transform = ''
            
        })
        window.setTimeout(() =>
        {
            sceneReady = true

        }, 2000)
    },
    (itemUrl, itemsLoaded, itemsTotal) =>
    {
        const progressRatio = itemsLoaded / itemsTotal
        loadingBarElement.style.transform = `scaleX(${progressRatio})`
    }
)




THREE.ColorManagement.enabled = false
const cursor = {
    x: 0,
    y: 0
}
window.addEventListener('mousemove', (event) => {
    cursor.x = -(event.clientX / sizes.width -0.5)
    cursor.y = (event.clientY / sizes.height -0.5 )   
})

// Debug
// const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

//Images
import WheeliebigImage from '../static/images/wheeliebig.jpeg/';
const imageElement = document.querySelector('.img_surron');
imageElement.src = WheeliebigImage;


import MeImage from '../static/images/musicme.png/';
const imageElementMe = document.querySelector('.img_me');
imageElementMe.src = MeImage;


import PicsImage from '../static/images/kennywheelie.jpeg/';
const imageElementPics = document.querySelector('.img_pics');
imageElementPics.src = PicsImage;

import MonitorImage from '../static/images/skillz.png/';
const imageElementMonitor = document.querySelector('.img_monitor');
imageElementMonitor.src = MonitorImage;

import ProfilePicImage from '../static/images/aboutme.jpeg';
const imageElementProfilePic = document.querySelector('.img_myself');
imageElementProfilePic.src = ProfilePicImage;

import ScreenShotImage from '../static/images/screenshot.png/';
const imageElementScreenShot = document.querySelector('.sceneSnapshot');
imageElementScreenShot.src = ScreenShotImage;

import NoteImage from '../static/images/note.gif';

import ImageForTexture from '../static/images/picwallme.png/';

//Video
import VideoFile from '../static/Video.mp4';
const videoElement = document.querySelector('.videoClass');
videoElement.src = VideoFile;

videoElement.autoplay = true;
videoElement.muted = true;
videoElement.loop = true;

const videoTexture = new THREE.VideoTexture(videoElement);


videoElement.addEventListener('play', function(e) {
    e.preventDefault();
    
});




/**
 * Loaders
 */
// Texture loader
const textureLoader = new THREE.TextureLoader(loadingManager);

// Draco loader
const dracoLoader = new DRACOLoader(loadingManager);
dracoLoader.setDecoderPath('draco/');

// GLTF loader
const gltfLoader = new GLTFLoader(loadingManager);
gltfLoader.setDRACOLoader(dracoLoader);

const bakedTexture = textureLoader.load('finalbake.jpg');
bakedTexture.flipY = false;
bakedTexture.colorSpace = THREE.SRGBColorSpace

const bakedMaterial = new THREE.MeshBasicMaterial({ 
    map: bakedTexture,
    transparent: true,
    opacity: 0
});

  
const floorLightMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
const topLightMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });

const floorLightGuitarMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff});
const floorLightBikeMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff});

const xtyzLightMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });

const cylindrlampnotesLightMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });

const lightFrontBikeMaterial = new THREE.MeshBasicMaterial({ color: 0xffffff });
const lightRearBikeMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000 });
const lightBlinkerBikeMaterial = new THREE.MeshBasicMaterial({ color: 0xffff00 });
const lightOff = new THREE.MeshBasicMaterial({ color: 0x555555 });


const screenMaterial = new THREE.MeshBasicMaterial({ 
    map: videoTexture, 
    transparent: true,
    opacity: 0 });
 

let macMiniMesh;
let chairMesh
let bikeMesh
let guitarMesh
let coneBevelMesh
let cubeBevelMesh
let icosphereBevelMesh
let cameraMesh
let screenMesh
let fanWingsMesh
let monitorMesh
let lampMesh

let lightFrontBikeMesh
let lightRearBikeMesh
let lightBlinkerBikeMesh
let lightLongRBikeMesh
let lightLongLBikeMesh

let bikeLightMesh
let guitarLightMesh
let lampBloomTopMesh
let lightBevelTopMesh
let lightBevelMidMesh
let lightBevelBotMesh
let xtyzLightMesh
let cylindrlampnotesMesh

const materials = [
    floorLightMaterial,
    topLightMaterial,
    floorLightGuitarMaterial,
    floorLightBikeMaterial,
    xtyzLightMaterial,
    cylindrlampnotesLightMaterial,
    lightFrontBikeMaterial,
    lightRearBikeMaterial,
    lightBlinkerBikeMaterial,
    lightOff
  ];
  
  materials.forEach(material => {
    material.transparent = true;
    material.opacity = 0;
    
  });


// let screenMaterial

let lastBlinkTime = 0;
let isBlinkerOn = false;

let isTextDivOpen = false;

const raycaster = new THREE.Raycaster();
const mouse = new THREE.Vector2();

window.addEventListener('mousemove', onMouseMove, false);

let hovered = false;
let currentRotationChair = 0;  
let currentRotationGuitar = 0;  

let targetRotationGuitar = currentRotationGuitar;
let targetRotationChair = currentRotationChair;

let isChairRotated = false;
let isGuitarRotated = false;

let isFanOn = true;  
let fanSpeed = 0.1;  
const fanDeceleration = 0.005;  
let isMonitorOn = true; 
let shakeIntensity = 0.02; 
let shakeStartTime = null;
const shakeDuration = 1000; 
let originalGuitarX = 0; 
let isBikeLightOn = true; 


const point0ElementPics = document.querySelector('.point-0');
const point1ElementBike = document.querySelector('.point-1');
const point2ElementGuitar= document.querySelector('.point-2');
const point3ElementMonitor= document.querySelector('.point-3');
const point4ElementAbout= document.querySelector('.point-4');



const picTextElement = document.querySelector('.picText');
const bikeTextElement = document.querySelector('.bikeText');
const guitarTextElement = document.querySelector('.guitarText');
const monitorTextElement = document.querySelector('.monitorText');
const aboutTextElement = document.querySelector('.aboutText');



let sceneIsVisible = true;

const fanRotationAxis = new THREE.Vector3(0, 1, 0);

const showAllButton = document.getElementById('showAllButton');



const xPosition = 0.9; 
const yPosition = 0.89;
const zPosition = -1.855;  
const xRotation = 0; 
const yRotation = 0;
const zRotation = -0.3;  
const imageHeight = 0.2;  
const imageWidth = 0.2;  


const imageTexture = textureLoader.load(ImageForTexture);

const imageMaterial = new THREE.MeshBasicMaterial({ 
    map: imageTexture,
    transparent: true,
    opacity: 0
});
const imageGeometry = new THREE.PlaneGeometry(imageWidth, imageHeight); 
const imageMesh = new THREE.Mesh(imageGeometry, imageMaterial);

imageMesh.position.set(xPosition, yPosition, zPosition); 
imageMesh.rotation.set(xRotation, yRotation, zRotation); 

scene.add(imageMesh); 

imageTexture.colorSpace = THREE.SRGBColorSpace;


// GLTF Loader

setTimeout(() => {

gltfLoader.load(
    'baked.glb',
    (gltf) => {
        gltf.scene.traverse((child) => { 
            child.material = bakedMaterial;
        });
        gltf.scene.rotation.y = THREE.MathUtils.degToRad(90);
        scene.add(gltf.scene);

        macMiniMesh = gltf.scene.children.find(child => child.name === 'McMni');
        cameraMesh = gltf.scene.children.find(child => child.name === 'CameraMerge');
        screenMesh = gltf.scene.children.find(child => child.name === 'VideoPlane');
        chairMesh = gltf.scene.children.find(child => child.name === 'ChairMerge');
        bikeMesh = gltf.scene.children.find(child => child.name === 'BikeMerge');

        guitarMesh = gltf.scene.children.find(child => child.name === 'GuitarMerge');
        coneBevelMesh = gltf.scene.children.find(child => child.name === 'ConeBevel');
        cubeBevelMesh = gltf.scene.children.find(child => child.name === 'CubeBevel');
        icosphereBevelMesh = gltf.scene.children.find(child => child.name === 'IcosphereBevel');
        fanWingsMesh = gltf.scene.children.find(child => child.name === 'FanWings');
        monitorMesh = gltf.scene.children.find(child => child.name === 'Screen');
        lampMesh = gltf.scene.children.find(child => child.name === 'Lampum');

        originalGuitarX = guitarMesh.position.x;

        bikeLightMesh = gltf.scene.children.find(child => child.name === 'BikeLight');
        guitarLightMesh = gltf.scene.children.find(child => child.name === 'GuitarLight');
        lampBloomTopMesh = gltf.scene.children.find(child => child.name === 'LampBloomTop');
        lightBevelTopMesh = gltf.scene.children.find(child => child.name === 'LightBevelTop');
        lightBevelMidMesh = gltf.scene.children.find(child => child.name === 'LightBevelMid');
        lightBevelBotMesh = gltf.scene.children.find(child => child.name === 'LightBevelBot');
        lightFrontBikeMesh = gltf.scene.children.find(child => child.name === 'Frontlight');
        lightRearBikeMesh = gltf.scene.children.find(child => child.name === 'Rearlight');
        lightBlinkerBikeMesh = gltf.scene.children.find(child => child.name === 'Blinker');
        lightLongLBikeMesh = gltf.scene.children.find(child => child.name === 'RearLongL');
        lightLongRBikeMesh = gltf.scene.children.find(child => child.name === 'RearLongR');

        xtyzLightMesh = gltf.scene.children.find(child => child.name === 'xtyzLamp');
        cylindrlampnotesMesh = gltf.scene.children.find(child => child.name === 'cylindrlampnotes');

        
        bikeLightMesh.material = floorLightBikeMaterial;
        guitarLightMesh.material = floorLightGuitarMaterial;
        lampBloomTopMesh.material = topLightMaterial;
        lightBevelTopMesh.material = floorLightMaterial;
        lightBevelMidMesh.material = floorLightMaterial;
        lightBevelBotMesh.material = floorLightMaterial;

        lightFrontBikeMesh.material = lightFrontBikeMaterial
        lightRearBikeMesh.material = lightRearBikeMaterial
        lightBlinkerBikeMesh.material = lightBlinkerBikeMaterial
        lightLongLBikeMesh.material = lightRearBikeMaterial
        lightLongRBikeMesh.material = lightRearBikeMaterial

        xtyzLightMesh.material = xtyzLightMaterial;
      
        cylindrlampnotesMesh.material = cylindrlampnotesLightMaterial;


        chairMesh.rotation.y = THREE.MathUtils.degToRad(0); 
        
        if(screenMesh && videoTexture) {
           
            screenMesh.material = screenMaterial;
        }
        if(screenMesh && screenMesh.material) {
            screenMesh.material.map = videoTexture;
            screenMesh.material.needsUpdate = true;
        }

    }
);
},1000);

showAllButton.addEventListener('click', function() {
    scene.traverse(function(child) {
        if (child instanceof THREE.Mesh) {
            child.visible = true;
        }
    });

    point0ElementPics.style.display = 'block';
    point1ElementBike.style.display = 'block';
    point2ElementGuitar.style.display = 'block';
    point3ElementMonitor.style.display = 'block';
    point4ElementAbout.style.display = 'block';
    showAllButton.style.display = 'none'; 


    sceneIsVisible = true;
});

function displayMusicNote(event) {

    const noteImage = document.createElement('img');
    noteImage.src = NoteImage;
    noteImage.style.position = 'absolute';
    noteImage.style.left = `${event.clientX - 20}px`;  
    noteImage.style.top = `${event.clientY}px`;
    
    if (window.innerWidth < 768) {
        noteImage.style.width = '25px';
        var offsetTop = 50;
    } else {
        noteImage.style.width = '50px';
        var offsetTop = 120;
    }

    noteImage.style.zIndex = 1000; 

    setTimeout(() => {
        noteImage.style.transition = 'top 1s';
        noteImage.style.top = `${event.clientY - offsetTop}px`;
    }, 10);

    document.body.appendChild(noteImage);

    setTimeout(() => {
        document.body.removeChild(noteImage);
    }, 1500); 
}



function onMouseMove(event) {
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
    
    raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObjects([chairMesh, macMiniMesh, bikeMesh, guitarMesh, cameraMesh, monitorMesh, fanWingsMesh,lampMesh]);

    if (intersects.length > 0 && !hovered) {
        hovered = true;

        if (intersects[0].object === chairMesh) {
            outlinePass.selectedObjects = [chairMesh];
            
        } else if (intersects[0].object === bikeMesh) {
            outlinePass.selectedObjects = [bikeMesh];
     
            
        } else if (intersects[0].object === guitarMesh) {
            outlinePass.selectedObjects = [guitarMesh];



            
        } else if (intersects[0].object === cameraMesh) {
            outlinePass.selectedObjects = [cameraMesh];

 
        } else if (intersects[0].object === monitorMesh) {
            outlinePass.selectedObjects = [monitorMesh];
          
        } else if (intersects[0].object === fanWingsMesh) {
            outlinePass.selectedObjects = [fanWingsMesh];
          
        }else if (intersects[0].object === lampMesh) {
            outlinePass.selectedObjects = [lampMesh];          
        }     
    } 
    else if (intersects.length === 0 && hovered) {
        hovered = false;
        outlinePass.selectedObjects = [];
    }
}


function onMouseClick(event) {
    mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
    mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

    raycaster.setFromCamera(mouse, camera);

    const intersects = raycaster.intersectObjects([chairMesh, macMiniMesh, bikeMesh, guitarMesh, cameraMesh, monitorMesh, fanWingsMesh, lampMesh, imageMesh]);

    if (intersects.length > 0 && !isTextDivOpen) {
    
          if (monitorMesh.visible && intersects[0].object === monitorMesh) {
            isMonitorOn = !isMonitorOn; 
            screenMesh.visible = isMonitorOn; 
            if (!monitorMesh.visible) {
                videoElement.pause();
            }
            if (isMonitorOn) {
                videoElement.currentTime = 0;
                videoElement.play(); 
            } else {
                videoElement.pause(); 
            }
        } else if (intersects[0].object === fanWingsMesh) {
            isFanOn = !isFanOn;
            if (isFanOn) {
                fanSpeed = 0.1; 
            }
        }  else if (intersects[0].object === chairMesh) {
            if (!isChairRotated) {
                targetRotationChair = 1.25;
                isChairRotated = true; 
            } else {
                targetRotationChair = 0;
                isChairRotated = false; 
            }   
        }  else if (intersects[0].object === guitarMesh) {
              
           
                if (!isGuitarRotated) {
                    targetRotationGuitar = shakeIntensity;
                    isGuitarRotated = true;
                    shakeStartTime = Date.now(); 
                } else {
                    targetRotationGuitar = 0;
                    isGuitarRotated = false;
                    shakeStartTime = null; 
                }

                displayMusicNote(event);

                
        } else if (intersects[0].object === lampMesh) {
            if (sceneIsVisible) {
                scene.traverse(function(child) {
                    if (child instanceof THREE.Mesh) {
                        child.visible = false;
                    }
                });
                
                point0ElementPics.style.display = 'none';
                point1ElementBike.style.display = 'none';
                point2ElementGuitar.style.display = 'none';
                point3ElementMonitor.style.display = 'none';
                point4ElementAbout.style.display = 'none';

                showAllButton.style.display = 'block'; 

                sceneIsVisible = false;
            }          
        }  else if (intersects[0].object === bikeMesh) {
            isBikeLightOn = !isBikeLightOn;
            if (isBikeLightOn) {
                lightFrontBikeMesh.material = lightFrontBikeMaterial;
                lightRearBikeMesh.material = lightRearBikeMaterial
                lightLongRBikeMesh.material = lightRearBikeMaterial
                lightLongLBikeMesh.material = lightRearBikeMaterial

                isBlinkerOn = true;
                lightBlinkerBikeMesh.material = lightBlinkerBikeMaterial

            } else {
                lightFrontBikeMesh.material = lightOff
                lightRearBikeMesh.material = lightOff
                lightLongRBikeMesh.material = lightOff
                lightLongLBikeMesh.material = lightOff
                isBlinkerOn = false;
                lightBlinkerBikeMesh.material = lightOff;
            }
        }else if (intersects[0].object === cameraMesh) {
            const flashEffect = document.getElementById('flashEffect');
    
            // Blitz
            flashEffect.style.transition = 'opacity 0.1s'; // Übergang
            flashEffect.style.opacity = 0.85; 
        
            // Blitz-Effekt ende
            setTimeout(() => {
                flashEffect.style.opacity = 0;
            }, 100);

            const sceneSnapshot = document.getElementById('sceneSnapshot');
            sceneSnapshot.style.display = "block";  
            setTimeout(() => {
                sceneSnapshot.style.opacity = "1";  
            }, 100);  
        
            setTimeout(() => {
                sceneSnapshot.style.opacity = "0";
                
                setTimeout(() => {
                    sceneSnapshot.style.display = "none";
                }, 1000);  
        
            }, 1000);
        }

    } else {
      
        targetRotationChair = 0;
        isTextDivOpen = false;
    }
}

window.addEventListener('click', onMouseClick);



document.addEventListener('DOMContentLoaded', function () {
    var closeButtons = document.querySelectorAll('.close-btn');
  
    closeButtons.forEach(function (button) {
      button.addEventListener('click', function (e) {
        const closestArticle = e.target.closest('article');
        
        if (!closestArticle) {
          console.error("article nicht gefunden");
          return; 
        }
  
        if (isTextDivOpen) {
          gsap.to(closestArticle, {
            duration: 0.1,
            opacity: 0,
            onComplete: () => {
              closestArticle.style.display = 'none';
              isTextDivOpen = false;
            }
          });
        }
  
        e.stopPropagation();
      });
    });
  });
  


document.addEventListener('click', function(e) {
    const clickedInsideArticle = e.target.closest('article');
    const clickedOnPoint = e.target === point0ElementPics || e.target === point1ElementBike || e.target === point2ElementGuitar || e.target === point3ElementMonitor || e.target === point4ElementAbout;

    if (!clickedInsideArticle && !clickedOnPoint) {
        closeAllWindows();
    }
});

function closeAllWindows() {
    const allTextElements = [picTextElement, bikeTextElement, guitarTextElement, monitorTextElement, aboutTextElement];

    allTextElements.forEach(element => {
        if (element.style.display !== 'none') {
            gsap.to(element, {
                duration: 0.1,
                opacity: 0,
                onComplete: () => {
                    element.style.display = 'none';
                }
            });
        }
    });

    isTextDivOpen = false;
}

function animateTextDiv(textElement) {
    gsap.fromTo(textElement, { opacity: 0 }, { duration: 0.1, opacity: 1 });
    gsap.fromTo(textElement.querySelectorAll('h3,h4,a,p.text'), {
        x: '-50%',
        opacity: 0
    }, {
        x: '0%',
        opacity: 1,
        duration: 0.5,
        stagger: 0.2
    });
}

point0ElementPics.addEventListener('click', (e) => {
    if (isTextDivOpen) {
        gsap.to(picTextElement, {
            duration: 0.1,
            opacity: 0,
            onComplete: () => {
                picTextElement.style.display = 'none';
            }
        });
        isTextDivOpen = false;
    } else {
        picTextElement.style.display = 'grid';
        animateTextDiv(picTextElement);
        isTextDivOpen = true;
    }
    e.stopPropagation();
});

point1ElementBike.addEventListener('click', (e) => {
    if (isTextDivOpen) {
        gsap.to(bikeTextElement, {
            duration: 0.1,
            opacity: 0,
            onComplete: () => {
                bikeTextElement.style.display = 'none';
            }
        });
        isTextDivOpen = false;
    } else {
        bikeTextElement.style.display = 'grid';
        animateTextDiv(bikeTextElement);
        isTextDivOpen = true;
    }
    e.stopPropagation();
});

point2ElementGuitar.addEventListener('click', (e) => {
    if (isTextDivOpen) {
        gsap.to(guitarTextElement, {
            duration: 0.1,
            opacity: 0,
            onComplete: () => {
                guitarTextElement.style.display = 'none';
            }
        });
        isTextDivOpen = false;
    } else {
        guitarTextElement.style.display = 'grid';
        animateTextDiv(guitarTextElement);
        isTextDivOpen = true;
    }
    e.stopPropagation();
});

point3ElementMonitor.addEventListener('click', (e) => {
    if (isTextDivOpen) {
        gsap.to(monitorTextElement, {
            duration: 0.1,
            opacity: 0,
            onComplete: () => {
                monitorTextElement.style.display = 'none';
            }
        });
        isTextDivOpen = false;
    } else {
        monitorTextElement.style.display = 'grid';
        animateTextDiv(monitorTextElement);
        isTextDivOpen = true;
    }
    e.stopPropagation();
});

point4ElementAbout.addEventListener('click', (e) => {
    if (isTextDivOpen) {
        gsap.to(aboutTextElement, {
            duration: 0.1,
            opacity: 0,
            onComplete: () => {
                aboutTextElement.style.display = 'none';
            }
        });
        isTextDivOpen = false;
    } else {
        aboutTextElement.style.display = 'grid';
        animateTextDiv(aboutTextElement);
        isTextDivOpen = true;
    }
    e.stopPropagation();
});

// Geometry
const particlesGeometry = new THREE.BufferGeometry()
const count = 2000
const positions = new Float32Array(count * 3) 


for (let i = 0; i < count * 3; i++) {
    const phi = Math.acos(1 - 2 * Math.random()); // Polarwinkel phi
    const theta = Math.random() * 2 * Math.PI; 
    const minRadius = 20;
    const maxRadius = 21;
    const radius = Math.cbrt(Math.random()) * (maxRadius - minRadius) + minRadius; 
    
    const x = radius * Math.sin(phi) * Math.cos(theta);
    const y = radius * Math.sin(phi) * Math.sin(theta);
    const z = radius * Math.cos(phi);

    const index = i * 3;
    positions[index] = x;
    positions[index + 1] = y;
    positions[index + 2] = z;

}

particlesGeometry.setAttribute('position', new THREE.BufferAttribute(positions, 3)) 

// Material
const particlesMaterial = new THREE.PointsMaterial({
    size: 0.1,
    sizeAttenuation: true,
    color: new THREE.Color('#ffffff')


    
})


// Points
const particles = new THREE.Points(particlesGeometry, particlesMaterial)
scene.add(particles)


/**
 * Points of interest
 */
let points;

if (window.innerWidth < 768) {
    // Werte für die mobile Ansicht
    points = [
        {   //////////////////////////y//z//x//
            position: new THREE.Vector3(-1.7, 1.85, 1.25),
            element: point0ElementPics
        },
        {
            position: new THREE.Vector3(-1.8, 1.3, 2),
            element: point2ElementGuitar
        },
        {
            position: new THREE.Vector3(0.3, 1.6, - 1.2),
            element: point1ElementBike
        },
        {
            position: new THREE.Vector3(-1.6, 1.6,  -0.8),
            element: point3ElementMonitor
        },
        {
            position: new THREE.Vector3(1.1, 1.8,  -1.5),
            element: point4ElementAbout
        }
    ];
} else {
    // Standardwerte (für Desktop)
    points = [
        {
            position: new THREE.Vector3(-1.7, 0.65, 1.25),
            element: point0ElementPics
        },
        {
            position: new THREE.Vector3(-1.8, 0.5, 1.8),
            element: point2ElementGuitar
        },
        {
            position: new THREE.Vector3(0.3, 1.1, - 1.2),
            element: point1ElementBike
        },
        {
            position: new THREE.Vector3(-1.6, 1.1,  -0.8),
            element: point3ElementMonitor
        },
        {
            position: new THREE.Vector3(1, 1.4,  -1.5),
            element: point4ElementAbout
        }
    ];
}





/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})
/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
camera.position.x = 5.5
camera.position.y = 2
camera.position.z = 5.5
// camera.lookAt(middleHelper.position)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
// controls.enableRotate = false; 
controls.enableZoom = true;   
controls.enablePan = false;  
function toRadians(degrees) {
    return degrees * (Math.PI / 180);
}
controls.addEventListener('change', function() {
    controls.target.clamp(minPan, maxPan);
});

const minPan = new THREE.Vector3(-1, 0, 0); // Min schwenkb Position
const maxPan = new THREE.Vector3(1, 0, 0);  // Max

controls.minPolarAngle = toRadians(0);
controls.maxPolarAngle = toRadians(90);

controls.minAzimuthAngle = toRadians(0);
controls.maxAzimuthAngle = toRadians(90);
//scroll
controls.minDistance = 5;  
controls.maxDistance = 10; 

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    logarithmicDepthBuffer: true
})
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))

const composer = new EffectComposer(renderer);
const renderPass = new RenderPass(scene, camera);
composer.addPass(renderPass);

const outlinePass = new OutlinePass(new THREE.Vector2(window.innerWidth, window.innerHeight), scene, camera);
outlinePass.edgeStrength = 3.0;  
outlinePass.edgeGlow = 0.1;     
outlinePass.edgeThickness = 1; 
outlinePass.visibleEdgeColor.set('#ffffff'); 
outlinePass.hiddenEdgeColor.set('#ffffff');  
composer.addPass(outlinePass);

const effectFXAA = new ShaderPass( FXAAShader );
effectFXAA.uniforms.resolution.value.set( 1 / window.innerWidth, 1 / window.innerHeight );
composer.addPass( effectFXAA );

const gammaCorrection = new ShaderPass( GammaCorrectionShader );
composer.addPass( gammaCorrection );

///////Flicker
function updateLightFlicker(material, flickerFlag) {
    if(!material || !material.color) return;

    let targetColor;
    if(flickerFlag) {
        const intensity = 0.1 + Math.random() * 0.9;
        targetColor = new THREE.Color(0xffffff);
        targetColor.multiplyScalar(intensity);
    } else {
        targetColor = new THREE.Color(0xffffff); 
    }

    const currentColor = new THREE.Color(material.color);
    currentColor.lerpColors(currentColor, targetColor, 0.1);
    material.color = currentColor;
}
function toggleFlicker(flickerVar, material) {
    flickerVar.value = !flickerVar.value;

    const nextTimeout = Math.random() * 1500 + 200; 
    setTimeout(() => {
        toggleFlicker(flickerVar, material);
    }, nextTimeout);
}
const flickerFlags = {
    top: { value: false },
    guitar: { value: false },
    bike: { value: false },
    tlong: { value: false }
};

function animate() {
    updateLightFlicker(topLightMaterial, flickerFlags.top.value);
    updateLightFlicker(floorLightGuitarMaterial, flickerFlags.guitar.value);
    updateLightFlicker(floorLightBikeMaterial, flickerFlags.bike.value);
    updateLightFlicker(xtyzLightMaterial, flickerFlags.tlong.value);
   
    

    requestAnimationFrame(animate);
}
toggleFlicker(flickerFlags.top, topLightMaterial);
toggleFlicker(flickerFlags.guitar, floorLightGuitarMaterial);
toggleFlicker(flickerFlags.bike, floorLightBikeMaterial);
toggleFlicker(flickerFlags.tlong, xtyzLightMaterial);


animate();

/**
 * Animate
 */
const clock = new THREE.Clock()
const scaleSpeed = 0.7

const tick = () => {
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()
    
    if(coneBevelMesh) {
        coneBevelMesh.rotation.y += 0.01; 
    }
    if(cubeBevelMesh) {
        cubeBevelMesh.rotation.y += 0.01;
    }
    if(icosphereBevelMesh) {
        icosphereBevelMesh.rotation.y += 0.01;
    }
    if (chairMesh) { 
        let alpha = targetRotationChair - currentRotationChair;
        currentRotationChair += alpha * 0.05;
        chairMesh.rotation.y = currentRotationChair -THREE.MathUtils.degToRad(90);
    }


    if (guitarMesh) {
        if (shakeStartTime && Date.now() - shakeStartTime <= shakeDuration) {
            let shakeAmount = Math.sin(Date.now() * 10) * shakeIntensity;
            guitarMesh.position.x = originalGuitarX + shakeAmount;
        } else {
            guitarMesh.position.x = originalGuitarX;
    
            if (shakeStartTime && Date.now() - shakeStartTime > shakeDuration) {
                isGuitarRotated = false; 
                shakeStartTime = null;
            }
        }
    }
    
    
    if(sceneReady){
        for(const point of points){
            // Get 2D screen position
            
            const screenPosition = point.position.clone()
            screenPosition.project(camera)
    
            const translateX = screenPosition.x * sizes.width * 0.5
            const translateY = - screenPosition.y * sizes.height * 0.5
    
            point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
            point.element.classList.add('visible')
        }
    }
    if(videoTexture) {
        videoTexture.needsUpdate = true;
    }

    if(fanWingsMesh) {
        if (isFanOn) {
            fanWingsMesh.rotateOnAxis(fanRotationAxis, fanSpeed);
        } else {
            if (fanSpeed > 0) {
                fanSpeed -= fanDeceleration;
                fanWingsMesh.rotateOnAxis(fanRotationAxis, fanSpeed);
            }
        }
    }
    if(lightBlinkerBikeMesh && isBikeLightOn) {
        if (isBikeLightOn) {
            if (elapsedTime - lastBlinkTime > 0.5) { //intervall
                if (lightBlinkerBikeMesh.material === lightBlinkerBikeMaterial) {
                    lightBlinkerBikeMesh.material = lightOff;
                } else {
                    lightBlinkerBikeMesh.material = lightBlinkerBikeMaterial;
                }
                lastBlinkTime = elapsedTime;
            }
        } else {
            lightBlinkerBikeMesh.material = lightOff;
        }    
    }

    particles.rotation.y = (elapsedTime * 0.01)
    particles.rotation.x = (elapsedTime * 0.01)
    const pulseFactor = Math.sin(elapsedTime * scaleSpeed);

    const blinkSpeed = 0.005;
    const delay = 100;  
    updateLightFlicker();
    // Render

    composer.render();
 
    window.requestAnimationFrame(tick)
}

tick()

//mobile

if(window.innerWidth < 768) {
   
    controls.minDistance = 10; 
    controls.maxDistance = 17; 
    outlinePass.enabled = false;
    camera.position.x = 8.5
    camera.position.y = 4
    camera.position.z = 8.5

}


window.addEventListener('resize', function() {
    if (window.innerWidth < 768) {
        outlinePass.enabled = false;
    } else {
        outlinePass.enabled = true;
    }
});



